import React, { useContext, useMemo } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import Confetti from "react-confetti"

import Tali from "../Tali"
import AttemptsList from "../AttemptsList"
import Emoji from "../Emoji"
import Word from "../Word"
import ControlsWrapper from "../QuizControlsWrapper"
import ImageQuestion from "../ImageQuestion"
import Keyboard from "../Keyboard"
import AnswerInput from "../AnswerInput"
import CurrentUser from "../CurrentUser"
import StarsCount from "../StarsCount"
import StarsFeedback from "../StarsFeedback"
import RocketPlanet from "../../images/planets/planet-with-rocket.png"
import BluePlanet from "../../images/planets/blue.png"
import WordProgressBar from "../WordProgressBar"

import { Loader } from "../../styles/common"
import { MainButton, BackButton } from "../../styles/buttons"

import { theme } from "../../styles/theme"
import ArrowIcon from "../../lib/icons/Arrow"

import useWindowDimensions from "../../hooks/useWindowDimensions"

import { UI_EVENT_NAMES } from "../../const_values"
import { useStudentStore } from "../../../store"
import { KeyboardContext } from "../Keyboard/KeyboardProvider"
import { ExerciseActivity } from "../../services/helpers"

const { EXERCISE } = UI_EVENT_NAMES
const isBrowser = typeof window !== "undefined"

const AttemptsDisplay = ({ attempts }) => (
  <ul className="attempts">
    {attempts.map((segments, i) => (
      <li key={`attempt-${i}`}>
        <Word segments={segments} displayChars displayFaulty hideMissing />
      </li>
    ))}
  </ul>
)

const TaskListingDisplay = ({
  task,
  exerciseState,
  requestedSequence,
  attempts,
  handleAbort,
  handleClearWarning,
  handleRetry,
  handleNext,
  handleSubmit,
  isLastAttempt,
  isSolvingNoWarning,
  isValidating,
  isFailure,
  isOver,
  isSuccess,
  hasWarning,
  showElaborateFeedback,
  warning,
  loadingTask,
}) => {
  const { width, height } = useWindowDimensions()
  const { student } = useStudentStore((store) => store)
  const { keyboardState } = useContext(KeyboardContext)
  const learnedWordsToday = useMemo(() => {
    const currentDate = new Date()
    const todayStart = new Date(currentDate)

    todayStart.setHours(0)
    todayStart.setMinutes(0)
    todayStart.setSeconds(0)

    const sessionsToday = student.inputSessionsByStudent.nodes.filter(
      (s) => s.startTime >= todayStart.getTime()
    )

    return sessionsToday.reduce(
      (previousValue, currentSession) =>
        previousValue + (currentSession.correct ? 1 : 0),
      0
    )
  }, [student])

  const rocketPlanet = RocketPlanet
  const bluePlanet = BluePlanet

  return (
    <>
      <BackButton onClick={handleAbort} alt="Aufgabe abbrechen">
        <ArrowIcon />
      </BackButton>

      <aside className="infos">
        <StarsCount />
        <CurrentUser pos="inline" />
      </aside>

      <div
        className="daily-progress"
        style={{
          cursor:
            learnedWordsToday >= student.dailyTarget ? "pointer" : "default",
        }}
        onClick={() => {
          if (learnedWordsToday >= student.dailyTarget) {
            navigate("/student/profile/space")
          }
        }}
      >
        {!loadingTask && (
          <WordProgressBar
            planet={
              learnedWordsToday >= student.dailyTarget
                ? rocketPlanet
                : bluePlanet
            }
            progressSizeRatio={16}
            targetLearnedWords={student.dailyTarget}
            realLearnedWords={learnedWordsToday}
            isHidden={false}
          />
        )}
      </div>
      <p className="welcome-hint">Was siehst du?</p>

      {!isFailure && !hasWarning && !isSuccess && !isOver && <Tali />}

      {isFailure && (
        <Tali
          title="😊"
          buttonLabel={isLastAttempt ? "Letzter Versuch" : "Nochmal versuchen"}
          buttonAction={handleRetry}
          mood="curious"
          backdrop={true}
        >
          {showElaborateFeedback && exerciseState.feedback && (
            <p>
              <strong>{exerciseState.feedback}</strong>
            </p>
          )}
          {showElaborateFeedback && exerciseState.feedforward && (
            <>
              <p>
                <strong>{exerciseState.feedforward}</strong>
              </p>
            </>
          )}
          {/* {exerciseState.currentAttempt === 4 && (
            <p>Noch nicht! Versuche es nochmal mit diesen Buchstaben!</p>
          )} */}
        </Tali>
      )}

      {hasWarning && (
        <Tali
          title="☝️ Ups!"
          buttonLabel="Weiter probieren"
          buttonAction={handleClearWarning}
          mood="curious"
          backdrop={true}
        >
          <p>{warning}</p>
        </Tali>
      )}

      {isOver && (
        <Tali
          title="😉 Das nächste Mal schaffst du es!"
          buttonLabel="Nächste Aufgabe"
          buttonAction={handleNext}
          mood="curious"
          backdrop={true}
        >
          {exerciseState.feedback && (
            <p>
              <strong>{exerciseState.feedback}</strong>
            </p>
          )}
          {exerciseState.feedforward && (
            <p>
              <strong>{exerciseState.feedforward}</strong>
            </p>
          )}
          <Word segments={requestedSequence} />
          <p>
            <em>{task.request.length}</em> schwierige Stellen!
          </p>
          <p>{exerciseState.currentAttempt} Versuche:</p>
          <AttemptsDisplay attempts={attempts} />
        </Tali>
      )}

      {isSuccess && (
        <>
          <Tali
            title={isLastAttempt ? "😄 Geschafft!" : "🎉 Super!"}
            mood="happy"
            backdrop={true}
            buttonAction={handleNext}
            buttonLabel="Nächste Aufgabe"
            // secondaryButton={
            //   !isLastAttempt && (
            //     <SmallIconButton onClick={handleFinish}>
            //       <DetailsIcon />
            //     </SmallIconButton>
            //   )
            // }
            background={
              <Confetti
                width={width}
                height={height}
                confettiSource={{
                  x: width / 3 - width / 8,
                  y: (height / 4) * 2,
                  w: width / 3,
                  h: 0,
                }}
                colors={[
                  theme.colors.accent.main,
                  theme.colors.accent.shade,
                  theme.colors.feedback.good.main,
                  theme.colors.feedback.good.shade,
                ]}
                initialVelocityX={6}
                initialVelocityY={18}
                gravity={0.15}
              />
            }
          >
            {exerciseState.feedforward && (
              <p>
                <h2>{exerciseState.feedforward}</h2>
                {/* <SpeakButton
                  audio={
                    assetBaseUrl +
                    feedFwdAudioFolder +
                    `/${exerciseState.feedforward.slice(0, -1)}.mp3`
                  }
                  rate={1}
                  disabled={!!exerciseState.feedforward}
                  autoPlay={true}
                /> */}
              </p>
            )}

            <Word segments={requestedSequence} />

            <p>
              {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
              <Emoji size="xl" label="Daumen hoch">
                👍
              </Emoji>{" "}
              <em>{task.request.length}</em> schwierige Stellen!
            </p>
            <br />
            <StarsFeedback
              attempt={exerciseState.currentAttempt}
              hitsCount={exerciseState.results.graphemeHits?.count}
            />
            <br />
            <p>Deine Versuche:</p>
            <AttemptsDisplay attempts={attempts} />
          </Tali>
        </>
      )}

      {!loadingTask && (
        <>
          <AttemptsList
            current={exerciseState.currentAttempt}
            isSolved={exerciseState.isSolved}
            inputs={attempts}
            unleashTheCarnifex
          />

          <ImageQuestion className="question" task={task} autoPlay />

          <div className="answer">
            <AnswerInput
              handleSubmit={async (e) => {
                if (isBrowser) {
                  try {
                    const submitActivity = new ExerciseActivity(
                      window.location.pathname,
                      EXERCISE.INPUT_SUBMITTED,
                      student.id,
                      null,
                      task.word,
                      keyboardState.input,
                      task.sid
                    )
                    submitActivity.writeToDB()
                  } catch (error) {
                    console.error("failed to log submitting input: ", error)
                  }
                }
                handleSubmit(e)
              }}
              currentAttempt={exerciseState.currentAttempt}
            />
            <MainButton
              onClick={async (e) => {
                if (isBrowser) {
                  try {
                    const submitActivity = new ExerciseActivity(
                      window.location.pathname,
                      EXERCISE.INPUT_SUBMITTED,
                      student.id,
                      "keyboard_enter",
                      task.word,
                      keyboardState.input,
                      task.sid
                    )
                    submitActivity.writeToDB()
                  } catch (error) {
                    console.error("failed to log submitting input: ", error)
                  }
                }
                handleSubmit(e)
              }}
            >
              <ArrowIcon />
            </MainButton>
          </div>

          <ControlsWrapper>
            {isSolvingNoWarning && <Keyboard task={task} />}

            {isValidating && <Loader />}
          </ControlsWrapper>
        </>
      )}
    </>
  )
}

TaskListingDisplay.propTypes = {
  task: PropTypes.shape({
    maxAttempts: PropTypes.number.isRequired,
    request: PropTypes.arrayOf(PropTypes.string).isRequired,
    word: PropTypes.string.isRequired,
    sentence: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    audioWord: PropTypes.string.isRequired,
    audioSentence: PropTypes.string.isRequired,
    speechRate: PropTypes.number,
    speechRateSentence: PropTypes.number,
    maxInputLength: PropTypes.number.isRequired,
    sid: PropTypes.string.isRequired,
  }).isRequired,
  exerciseState: PropTypes.shape({
    feedback: PropTypes.string,
    feedforward: PropTypes.string,
    inputs: PropTypes.array,
    currentAttempt: PropTypes.number,
    isSolved: PropTypes.bool,
    correct: PropTypes.array,
    results: PropTypes.shape({
      matched: PropTypes.array,
      next: PropTypes.string,
      isCorrect: PropTypes.bool,
      graphemeHits: PropTypes.shape({
        count: PropTypes.number,
        letterCount: PropTypes.number,
      }),
      continue: PropTypes.bool,
      message: PropTypes.string,
    }),
  }).isRequired,
  attempts: PropTypes.array,
  handleAbort: PropTypes.func,
  handleClearWarning: PropTypes.func,
  handleRetry: PropTypes.func,
  handleNext: PropTypes.func,
  isLastAttempt: PropTypes.bool,
  isSolvingNoWarning: PropTypes.bool,
  isValidating: PropTypes.bool,
  isFailure: PropTypes.bool,
  isOver: PropTypes.bool,
  isSuccess: PropTypes.bool,
  hasWarning: PropTypes.bool,
  showElaborateFeedback: PropTypes.bool,
  warning: PropTypes.string,
  handleSubmit: PropTypes.func,
}

export default TaskListingDisplay
