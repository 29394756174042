import styled from "styled-components"
import { fluidRange } from "polished"

const QuizControlsWrapper = styled.div.attrs({ className: "controls" })`
  display: grid;
  place-items: center;
  align-self: end;

  ${fluidRange({
    prop: "padding",
    fromSize: "6px",
    toSize: "28px",
  })}
  transition: all .5s ease-in;
`

export default QuizControlsWrapper
