import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { SmallIconButton } from "../../styles/buttons"

const SpeakButton = styled(SmallIconButton)`
  padding-left: 1vmax;
  padding-top: 1vmax;
  padding-right: 1vmax;
  padding-bottom: 1vmax;

  svg {
    display: block;
    width: calc(18px + (100vmin - 375px) * 0.019);
    height: calc(18px + (100vmin - 375px) * 0.019);
  }
`

const SpeakButtonDisplay = ({
  onClick,
  children,
  disabled = false,
  className,
  audio,
}) => (
  <SpeakButton disabled={disabled} onClick={onClick} className={className}>
    {children}
  </SpeakButton>
)

SpeakButtonDisplay.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default SpeakButtonDisplay
