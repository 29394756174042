import styled from "styled-components"

export const StyledKeyboard = styled.div`
  user-select: none;

  position: relative;
  display: grid;
  grid-auto-rows: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacings.fluid.small}vh;
  width: auto;

  .row {
    display: grid;
    grid-auto-columns: repeat(12, 1fr);
    grid-auto-flow: column;
    width: auto;
    margin-left: 0;
    margin-right: 0;

    justify-items: start;
    gap: ${({ theme }) => theme.spacings.fluid.tiny}vmin;
    /* &:last-child span:not(.char) {
      margin-right: 2vmax;
    } */
    /* height: 7vh;
    max-height: 60px; */
  }
`

export const StyledKeyboardSpecial = styled.div`
  user-select: none;
  display: grid;
  justify-items: center;
  align-items: center;
  @media (orientation: landscape) {
    @media (max-width: 1000px) {
      width: 95%;
    }
    @media (min-width: 1001px) {
      width: calc(85% - 5vw);
    }
  }
  @media (orientation: portrait) {
    width: calc(100% - 20vw);
  }
  .area0 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: ". . . . . .";
    grid-area: area0;
    justify-self: stretch;
  }
  .area1,
  .area8 {
    display: grid;
    grid-template-columns: 1fr, 1fr, 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ". . .";
    justify-self: stretch;
  }
  .area1 {
    grid-area: area1;
  }
  .area8 {
    grid-area: area8;
  }
  .area2,
  .area3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      ". ."
      ". .";
    justify-self: stretch;
  }
  .area2 {
    grid-area: area2;
  }
  .area3 {
    grid-area: area3;
  }
  .area4,
  .area6 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      ". ."
      ". ."
      ". .";
    justify-self: stretch;
  }
  .area4 {
    grid-area: area4;
  }
  .area6 {
    grid-area: area6;
  }
  .area5 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      ". ."
      ". ."
      ". ."
      ". .";
    grid-area: area5;
    justify-self: stretch;
  }
  .area7 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ".";
    grid-area: area7;
    justify-self: stretch;
  }
  .area9 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ". . . .";
    grid-area: area9;
    justify-self: stretch;
  }
  .area10,
  .area11 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ".";
    width: min-content;
    justify-self: stretch;
    width: 100%;
    white-space: nowrap;
  }
  .area10 {
    grid-area: area10;
  }
  .area11 {
    grid-area: area11;
  }
  .area12 {
    grid-area: area12;
  }
  @media (orientation: portrait) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-template-rows: repeat(10, minmax(0, 1fr));
    grid-template-areas:
      "area0 area0 area0 area0 area0 area0"
      ". area1 area1 area1 . ."
      "area2 area2 . . . area10"
      "area2 area2 area5 area5 . area7"
      "area4 area4 area5 area5 area6 area6"
      "area4 area4 area5 area5 area6 area6"
      "area4 area4 area5 area5 area6 area6"
      "area3 area3 area9 area9 area9 area9"
      "area3 area3 area8 area8 area8 ."
      "area11 area12 . . . .";
    .area0,
    .area1,
    .area2 {
      margin-top: -24px;
      span {
        justify-self: center;
      }
    }
    .area3 {
      margin-bottom: -12px;
      span:nth-child(1),
      span:nth-child(3) {
        justify-self: center;
      }
    }
    .area4 {
      span:nth-child(1),
      span:nth-child(3),
      span:nth-child(5) {
        justify-self: center;
      }
    }
    .area5 {
      span:nth-child(1),
      span:nth-child(3),
      span:nth-child(5),
      span:nth-child(7) {
        justify-self: center;
      }
    }
    .area6 {
      span:nth-child(1),
      span:nth-child(3),
      span:nth-child(5) {
        justify-self: center;
      }
    }
    .area8,
    .area9 {
      margin-bottom: -12px;
    }
    .area8 {
      z-index: 2;
      span:nth-child(1) {
        justify-self: end;
      }
      span:nth-child(2) {
        justify-self: center;
      }
      span:nth-child(3) {
        justify-self: start;
      }
    }
    .area9 {
      z-index: 1;
      span:nth-child(1),
      span:nth-child(3) {
        justify-self: center;
      }
    }
    .area10 {
      align-self: start;
      justify-self: center;
    }
    .area12 {
      justify-self: end;
      margin-bottom: -12px;
    }
  }
  @media (orientation: landscape) {
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "area0 area0 area0 area0 area0 area0 area5 area5 area7 area10"
      ". area1 area1 area1 area4 area4 area5 area5 area6 area6"
      "area2 area2 area3 area3 area4 area4 area5 area5 area6 area6"
      "area2 area2 area3 area3 area4 area4 area5 area5 area6 area6"
      "area11 area12 . area8 area8 area8 area9 area9 area9 area9";
    .area0,
    .area1,
    .area2 {
      margin-top: -12px;
    }
    .area3 {
      span:nth-child(1),
      span:nth-child(3) {
        justify-self: center;
      }
    }
    .area4,
    .area6 {
      span:nth-child(1),
      span:nth-child(3),
      span:nth-child(5) {
        justify-self: center;
      }
    }
    .area5 {
      span:nth-child(1),
      span:nth-child(3),
      span:nth-child(5),
      span:nth-child(7) {
        justify-self: center;
      }
    }
    .area6 {
      z-index: 1;
    }
    .area7 {
      justify-self: center;
    }
    .area8 {
      span:nth-child(1) {
        justify-self: end;
      }
      span:nth-child(2) {
        justify-self: center;
      }
      span:nth-child(3) {
        justify-self: start;
      }
    }
    .area9 {
      z-index: 2;
      span:nth-child(1),
      span:nth-child(3) {
        justify-self: center;
      }
      span:nth-child(2),
      span:nth-child(4) {
        justify-self: start;
      }
    }
    .area12 {
      justify-self: end;
      margin-top: -12px;
    }
  }
`
