import React, { useEffect, useState, useRef, useCallback } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { ToastContainer, toast } from "react-toastify"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TaskListing from "../components/TaskListing"
import DEFAULT_MODES, {
  getKeyboardModes,
} from "../components/Keyboard/configurations"
import KeyboardProvider from "../components/Keyboard/KeyboardProvider"
import { QuizLayout, Loader } from "../styles/common"
import Api from "../services/api"
import { useStudentStore, useGraphqlClientStore } from "../../store.js"
import {
  WORD_QUERY,
  CREATE_INPUT_SESSION_MUTATION,
  DELETE_INPUT_SESSION_BY_ID_MUTATION,
} from "../graphql_requests"
import {
  fetchFeedforwardsFromCSV,
  fetchWordsFromCSV,
  StudentActivity,
  updateCurrentStudentData,
} from "../services/helpers"
import { UI_EVENT_NAMES } from "../const_values"
import useWindowDimensions from "../hooks/useWindowDimensions"
import { isMobileSafari } from "react-device-detect"
import { renderTurningPhone } from "../styles/helpers"

const { ALL } = UI_EVENT_NAMES

const api = new Api()
const TASK_DEFAULT = { maxAttempts: 4, request: [] }
const assetBaseUrl = process.env.GATSBY_ASSET_BASE_URL || ""
const audioWordPath = process.env.GATSBY_AUDIO_WORD_PATH || ""
const audioSentencePath = process.env.GATSBY_AUDIO_SENTENCE_PATH || ""

const ErrorMessage = styled.div`
  background-color: white;
  padding: 1em;
  position: absolute;
  inset: 2em;
  overflow: auto;
`

const LoadedExercise = ({
  exercise,
  setWord,
  inputSessionId,
  setInputSessionId,
  wordBuffer,
  setWordBuffer,
  wordBufferIndex,
  getNextWordFromList,
  getFeedForwardFromList,
}) => {
  const student = useStudentStore((store) => store.student)
  const [task, setTask] = useState({
    ...TASK_DEFAULT,
    ...exercise,
    request: exercise.graphemes,
    img: exercise.imgLarge,
    audioWord: `${assetBaseUrl}/${audioWordPath}/${exercise.audioWord}`,
    audioSentence: `${assetBaseUrl}/${audioSentencePath}/${exercise.audioSentence}`,
    maxInputLength: exercise.word.length + 5,
  })
  const [modes, setModes] = useState({ ...DEFAULT_MODES })
  const [chars, setChars] = useState()
  const [isPreparing, setIsPreparing] = useState(true)

  useEffect(() => {
    if (student) {
      setTask((prevTask) => ({
        ...prevTask,
        ...exercise,
        request: exercise.graphemes,
        img: exercise.imgLarge,
        audioWord: `${assetBaseUrl}/${audioWordPath}/${exercise.audioWord}`,
        audioSentence: `${assetBaseUrl}/${audioSentencePath}/${exercise.audioSentence}`,
        maxInputLength: exercise.word.length + 5,
        uid: student.id,
        sid: inputSessionId,
      }))
      setChars([...exercise.graphemes])
    }
  }, [exercise, student])

  useEffect(() => {
    if (!!student && !!chars) {
      const newKeyboardModes = getKeyboardModes(student.beginnerLevel, chars)
      setModes({
        ...newKeyboardModes,
      })
      setIsPreparing(false)
    }
  }, [chars, student])

  useEffect(() => {
    setTask((prev) => ({ ...prev, sid: inputSessionId }))
  }, [inputSessionId])

  return (
    <>
      {!isPreparing && (
        <KeyboardProvider modes={modes} maxLength={task.maxInputLength}>
          <TaskListing
            task={task}
            setWord={setWord}
            inputSessionId={inputSessionId}
            setInputSessionId={setInputSessionId}
            wordBuffer={wordBuffer}
            setWordBuffer={setWordBuffer}
            wordBufferIndex={wordBufferIndex}
            getNextWordFromList={getNextWordFromList}
            getFeedForwardFromList={getFeedForwardFromList}
          />
        </KeyboardProvider>
      )}
    </>
  )
}

const ExerciseDisplay = ({
  queryWord,
  setWord,
  inputSessionId,
  setInputSessionId,
  wordBuffer,
  setWordBuffer,
  wordBufferIndex,
  getNextWordFromList,
  getFeedForwardFromList,
}) => {
  const [loading, setLoading] = useState(true)
  const [dbWord, setDbWord] = useState()
  const { graphqlClient } = useGraphqlClientStore((store) => store)

  const fetchWord = useCallback(async () => {
    try {
      const fetchWordResult = await graphqlClient.request(WORD_QUERY, {
        word: queryWord,
      })
      setDbWord(fetchWordResult.wordByWord)
      setLoading(false)
    } catch (error) {
      toast.error(`Etwas ist schiefgelaufen: ${error.message}`)
      console.error(error)
    }
  }, [queryWord])

  useEffect(() => {
    if (queryWord) {
      setLoading(true)
      fetchWord()
    }
  }, [queryWord])

  return loading ? (
    <Loader className="loader" />
  ) : !!!dbWord ? (
    <ErrorMessage>
      <h2>Ooops</h2>
      <p>
        Leider keine Aufgabe für das Wort {queryWord} gefunden. Server Antwort:
      </p>
    </ErrorMessage>
  ) : (
    <LoadedExercise
      exercise={dbWord}
      setWord={setWord}
      inputSessionId={inputSessionId}
      setInputSessionId={setInputSessionId}
      wordBuffer={wordBuffer}
      setWordBuffer={setWordBuffer}
      wordBufferIndex={wordBufferIndex}
      getNextWordFromList={getNextWordFromList}
      getFeedForwardFromList={getFeedForwardFromList}
    />
  )
}

const ExercisePage = () => {
  const isBrowser = typeof window !== "undefined"

  // const { height } = useWindowSize()
  const { height } = useWindowDimensions()
  const [urlPath, setUrlPath] = useState(
    isBrowser ? window.location.pathname : null
  )
  const [queryWord, setQueryWord] = useState()
  const [inputSessionId, setInputSessionId] = useState()
  const [wordBuffer, setWordBuffer] = useState([])
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const wordBufferIndex = useRef(0)
  const staticWords = useRef([])
  const staticFeedforwards = useRef({})
  const usedIndices = useRef([])
  const student = useStudentStore((store) => store.student)
  const { graphqlClient } = useGraphqlClientStore((store) => store)

  const getNextWordFromList = useCallback(() => {
    let randomIndex
    do {
      randomIndex = Math.floor(Math.random() * staticWords.current.length)
    } while (usedIndices.current.includes(randomIndex))

    usedIndices.current.push(randomIndex)
    if (usedIndices.current.length === 10) {
      usedIndices.current.shift()
    }

    return staticWords.current[randomIndex]
  }, [])

  const getFeedForwardFromList = useCallback(
    (attempt, isCorrect, letterCount) => {
      const feedfwdCategory = isCorrect
        ? "right"
        : letterCount !== 0
        ? `partlyRight${attempt.toString()}`
        : `wrong${attempt.toString()}`

      let randomIndex = Math.floor(
        Math.random() * staticFeedforwards.current[feedfwdCategory].length
      )

      const newFeedforward = staticFeedforwards.current[feedfwdCategory][
        randomIndex
      ]
        .split("XY")
        .join(letterCount.toString())

      return newFeedforward
    },
    []
  )

  const handleRequestWordError = useCallback(
    async (error, sessionId) => {
      console.error(
        `something went wrong${
          sessionId ? " while requesting next word" : ""
        }:`,
        error
      )

      if (sessionId) {
        try {
          await graphqlClient.request(DELETE_INPUT_SESSION_BY_ID_MUTATION, {
            id: sessionId,
          })
        } catch (e) {
          console.error("deleting input session failed:", e)
        } finally {
          navigate("/student/profile")
        }
      }
    },
    [graphqlClient]
  )

  const loadExerciseResources = useCallback(async () => {
    if (student) {
      try {
        const newSession = await graphqlClient.request(
          CREATE_INPUT_SESSION_MUTATION,
          {
            attempt: 0,
            input: "",
            correct: false,
            student: student.id,
          }
        )
        const newSessionId = newSession.createInputSession.inputSession.id
        setInputSessionId(newSessionId)

        staticFeedforwards.current = await fetchFeedforwardsFromCSV()

        if (student.beginnerLevel !== 0 && student.beginnerLevel !== 4) {
          staticWords.current = await fetchWordsFromCSV(student.beginnerLevel)

          console.log("choose random word from static list")
          setQueryWord(getNextWordFromList())
        } else {
          const mlPayload = {
            attempt: 0,
            word: "",
            current: "",
            isCorrect: true,
            uid: student.id,
            sid: newSessionId,
          }

          console.log("ML API payload:", mlPayload)

          try {
            const mlResponse = await api.pushResult(mlPayload)

            console.log("ML Response:", mlResponse)
            setWordBuffer(mlResponse.next_words)
            setQueryWord(mlResponse.next_words[0])
            wordBufferIndex.current = 0
          } catch (e) {
            handleRequestWordError(e, newSessionId)
          }
        }
      } catch (error) {
        handleRequestWordError(error)
      }
    }
  }, [student])

  const finishPageLoading = async () => {
    await checkStudentData()

    try {
      const visitActivity = new StudentActivity(
        urlPath,
        ALL.PAGE_VISITED,
        student.id
      )
      await visitActivity.writeToDB()
    } catch (e) {
      console.error("failed to log page visiting: ", e)
    }

    window.addEventListener(
      isMobileSafari ? "pagehide" : "beforeunload",
      logPageLeaving
    )
  }

  const logPageLeaving = async (event) => {
    try {
      const leaveActivity = new StudentActivity(
        urlPath,
        ALL.PAGE_LEAVED,
        student.id
      )
      await leaveActivity.writeToDB()
    } catch (e) {
      console.error("failed to log page leaving: ", e)
    }

    if (event) {
      event.returnValue = ""
    }

    return ""
  }

  const checkStudentData = async () => {
    try {
      const updateSuccessResult = await updateCurrentStudentData()
      console.log("update success:", updateSuccessResult)

      if (!updateSuccessResult.success && isBrowser) {
        navigate("/student", { replace: true })
      } else if (updateSuccessResult.success) setIsAuthenticated(true)
    } catch (error) {
      console.error(error)
      if (isBrowser) navigate("/student", { replace: true })
    }
  }

  useEffect(() => {
    finishPageLoading()

    return () => {
      if (isBrowser) {
        window.removeEventListener(
          isMobileSafari ? "pagehide" : "beforeunload",
          logPageLeaving
        )
      }

      logPageLeaving()
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) loadExerciseResources()
  }, [isAuthenticated])

  useEffect(() => {
    console.log("next word:", queryWord)
  }, [queryWord])

  return (
    <>
      <ToastContainer theme="colored" autoClose={2000} />
      {!!height && (
        <Layout layoutHeight={height}>
          <Seo title="Aufgabe" />
          <QuizLayout>
            <div className="ls-view">
              {queryWord && student ? (
                <ExerciseDisplay
                  queryWord={queryWord}
                  setWord={setQueryWord}
                  inputSessionId={inputSessionId}
                  setInputSessionId={setInputSessionId}
                  wordBuffer={wordBuffer}
                  setWordBuffer={setWordBuffer}
                  wordBufferIndex={wordBufferIndex}
                  getNextWordFromList={getNextWordFromList}
                  getFeedForwardFromList={getFeedForwardFromList}
                />
              ) : (
                <Loader className="loader" />
              )}
            </div>
            <div className="pt-view">{renderTurningPhone()}</div>
          </QuizLayout>
        </Layout>
      )}
    </>
  )
}
export default ExercisePage
