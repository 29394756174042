import styled from "styled-components"

const WordWrapper = styled.span`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2px;
  line-height: 1;
  font-family: "Grundschrift Bold";
  font-style: normal;
  width: 100%;
  @media (orientation: portrait) {
    font-size: calc(100vw / (64 * 0.64) + 8px);
  }

  @media (orientation: landscape) {
    font-size: calc(100vw / 64);
  }
`

export default WordWrapper
