import React, { useContext } from "react"
import { isMobile } from "react-device-detect"

import { KeyboardContext } from "../Keyboard/KeyboardProvider"
import { useStudentStore } from "../../../store"

import StyledAnswerInput, { ClearInputButton } from "./AnswerInputStyles"
import CloseIcon from "../../lib/icons/Close"

const AnswerInputDisplay = ({
  handleSubmit,
  currentAttempt,
  setInput,
  ...props
}) => {
  const { keyboardState, clearInput, setKeyboardState } =
    useContext(KeyboardContext)
  const student = useStudentStore((store) => store.student)
  const { input, displayInput, currentMode } = keyboardState

  return (
    <StyledAnswerInput>
      <input
        autoFocus
        value={displayInput}
        disabled={isMobile}
        onKeyDown={(e) => {
          const { key, type } = e
          if (!student.beginnerLevel && key === "Enter" && type === "keydown") {
            console.log("Enter has been pressed.")
            handleSubmit()
          }
        }}
        onChange={(e) => {
          setInput(e)
        }}
        onContextMenu={(e) => {
          e.preventDefault()
        }}
        onPaste={(e) => {
          e.preventDefault()
        }}
      />
      <ClearInputButton
        disabled={input.length === 0}
        onClick={() => {
          clearInput()
          if (currentMode === "exhausted") {
            setKeyboardState((prev) => ({
              ...prev,
              currentMode: currentAttempt === 4 ? "assist" : "default",
              cursorPosition: 0,
            }))
          }
        }}
      >
        <CloseIcon />
      </ClearInputButton>
    </StyledAnswerInput>
  )
}

export default AnswerInputDisplay
