import styled from "styled-components"

import Letter from "../Letter"

export const Char = styled(Letter)`
  color: ${({ theme }) => theme.colors.gray100};
  background-color: white;
  border: 0.075em solid currentColor;
  width: 0.7em;
  text-align: center;
  @media (orientation: portrait) {
    font-size: calc(100vw / (64 * 0.64) + 8px);
  }

  @media (orientation: landscape) {
    font-size: calc(100vw / 64 + 8px);
  }
`

export const CharCorrect = styled(Char)`
  color: white;
  background-color: ${({ theme }) => theme.colors.feedback.good.main};
  border-color: ${({ theme }) => theme.colors.feedback.good.main};
  width: min-content;
`

export const CharFaulty = styled(Char)`
  color: white;
  background-color: ${({ theme }) => theme.colors.feedback.bad.main};
  border-color: ${({ theme }) => theme.colors.feedback.bad.main};
`

export const CharDel = styled(Char).attrs({ as: "del" })`
  color: ${({ theme }) => theme.colors.feedback.bad.main};
  background-color: currentColor;
  border-color: currentColor;
  height: 2px;
  overflow: hidden;
`

export const CharIns = styled(Char).attrs({ as: "ins" })`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.feedback.bad.main};
  background-color: currentColor;
  border-color: currentColor;
  height: 2px;
  overflow: hidden;
`
