import React, { useCallback, useRef, useState } from "react"
import PropTypes from "prop-types"
import useSound from "use-sound"
import { isMobile } from "react-device-detect"

import KeyDisplay from "./KeyDisplay"
import { TimeLogger } from "../../services/helpers"
import { KEYSOUND_PLAY_THRESHOLD_MS } from "../../const_values"

const Key = ({
  value,
  icon,
  disabled,
  audioName,
  handleActivate,
  handleDeactivate,
  handleKeyEvent,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false)

  const keyTouchTimeLogger = useRef(new TimeLogger())
  const keyDoubleTouchTimeoutHandler = useRef()

  const [play] = useSound(
    `https://files.talidu.net/audios_keyboard/${audioName}.m4a`
  )

  const handleKeyPress = useCallback(
    (e) => {
      setIsActive(true)
      handleActivate(e)
      if (isMobile) {
        if (!keyTouchTimeLogger.current.isRunning) {
          keyTouchTimeLogger.current.start()
          keyDoubleTouchTimeoutHandler.current = setTimeout(() => {
            handleKeyEvent(e)
            keyTouchTimeLogger.current.reset()
          }, KEYSOUND_PLAY_THRESHOLD_MS)
        } else {
          keyTouchTimeLogger.current.end()
          if (
            keyTouchTimeLogger.current.calculateDuration() <
            KEYSOUND_PLAY_THRESHOLD_MS
          ) {
            clearTimeout(keyDoubleTouchTimeoutHandler.current)
            play()
          }

          keyTouchTimeLogger.current.reset()
        }
      } else if (e.button === 0) {
        handleKeyEvent(e)
      } else if (e.button === 2) {
        e.preventDefault()
        play()
      }
    },
    [handleActivate, play]
  )

  const handleKeyRelease = useCallback(
    (e) => {
      setIsActive(false)
      handleDeactivate(e)
    },
    [handleDeactivate]
  )

  return (
    <KeyDisplay
      handleKeyPress={handleKeyPress}
      handleKeyRelease={handleKeyRelease}
      isMobile={isMobile}
      disabled={disabled}
      isActive={isActive}
      icon={icon}
      value={value}
      {...props}
    />
  )
}

Key.propTypes = {
  active: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  row: PropTypes.number,
  className: PropTypes.string,
  icon: PropTypes.element,
  area: PropTypes.number,
  audioName: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleActivate: PropTypes.func,
  handleDeactivate: PropTypes.func,
  handleKeyEvent: PropTypes.func,
}

export default Key
