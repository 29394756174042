// basic stuff
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { toast } from "react-toastify"

// components
import TaskListingDisplay from "./TaskListingDisplay"
import ConfirmationAlert from "../ConfirmationAlert"

// hooks
import { usePoints } from "../../hooks"

// context variables
import { KeyboardContext } from "../Keyboard/KeyboardProvider"

// exercise validation
import { exerciseService } from "../../services/validation/exercise"

// graphQL stuff
import { useGraphqlClientStore } from "../../../store"
// import graphqlClient from "../../services/graphql-client.js"
import {
  CREATE_INPUT_SESSION_MUTATION,
  UPDATE_INPUT_SESSION_BY_ID_MUTATION,
} from "../../graphql_requests"

// constants
import {
  DEFAULT_MESSAGES,
  EXERCISE_TASKS,
  ACTIVITY_TIMEOUT_MS,
  UI_EVENT_NAMES,
} from "../../const_values"

// current user
import { useStudentStore } from "../../../store"

// helpers
import {
  TimeLogger,
  calculateStarsFromAttempt,
  ExerciseActivity,
} from "../../services/helpers"

const isBrowser = typeof window !== "undefined"

const { EXERCISE } = UI_EVENT_NAMES

// main component
const TaskListing = ({
  task,
  setWord,
  inputSessionId,
  setInputSessionId,
  wordBuffer,
  setWordBuffer,
  wordBufferIndex,
  getNextWordFromList,
  getFeedForwardFromList,
}) => {
  // states
  const [warning, setWarning] = useState(null)
  const [hasWarning, setHasWarning] = useState(false)
  const [isSolvingNoWarning, setIsSolvingNoWarning] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [isFailure, setIsFailure] = useState(false)
  const [isOver, setIsOver] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLastAttempt, setIsLastAttempt] = useState(false)
  const [showElaborateFeedback, setShowElaborateFeedback] = useState(false)
  const [exerciseState, setExerciseState] = useState({
    feedback: null,
    feedforward: null,
    inputs: [],
    currentAttempt: 1,
    isSolved: false,
    correct: [],
    results: {},
  })
  const [currentTask, setCurrentTask] = useState(EXERCISE_TASKS.solving)
  const [loadingTask, setLoadingTask] = useState(false)
  const [isContinueConfirmationOpen, setIsContinueConfirmationOpen] =
    useState(false)

  const { student, appendSession } = useStudentStore((store) => store)
  const { graphqlClient } = useGraphqlClientStore((store) => store)

  const sessionTimer = useRef(new TimeLogger())
  const feedbackTimer = useRef(new TimeLogger())
  const activityTimeout = useRef()

  const keyboardContext = useContext(KeyboardContext)

  const { updateStars } = usePoints()

  const requestedSequence = useMemo(
    () =>
      task.request.map((sequence, index) => ({
        index,
        sequence,
        variant: "correct",
      })),
    [task.request]
  )
  const attempts = useMemo(
    () =>
      !!exerciseState.inputs.length
        ? exerciseState.inputs.map((input) => exerciseState.results[input])
        : [],
    [exerciseState.inputs, exerciseState.results]
  )

  const handleActivityTimeout = useCallback(async () => {
    if (isBrowser) {
      try {
        const popupOpenActivity = new ExerciseActivity(
          window.location.pathname,
          EXERCISE.INACTIVITY_POPUP_SHOWED,
          student.id,
          null,
          task.word,
          keyboardContext.keyboardState.input,
          task.sid
        )

        popupOpenActivity.writeToDB()
      } catch (error) {
        console.error("failed to log inactivity popup opening: ", error)
      }
    }
    switch (currentTask) {
      case EXERCISE_TASKS.solving:
        console.log("NONSENSE!!!!!")
        sessionTimer.current.break()
        break
      case EXERCISE_TASKS.failure:
      case EXERCISE_TASKS.over:
      case EXERCISE_TASKS.success:
        feedbackTimer.current.break()
    }

    setIsContinueConfirmationOpen(true)
  }, [currentTask])

  const handleContiuneConfirm = useCallback(async () => {
    if (isBrowser) {
      try {
        const exerciseContinueActivity = new ExerciseActivity(
          window.location.pathname,
          EXERCISE.EXERCISE_CONTINUED,
          student.id,
          "exercise_continue",
          task.word,
          keyboardContext.keyboardState.input,
          task.sid
        )

        exerciseContinueActivity.writeToDB()
      } catch (error) {
        console.error("failed to log exercise continuing: ", error)
      }
    }
    switch (currentTask) {
      case EXERCISE_TASKS.solving:
        sessionTimer.current.start()
        break
      case EXERCISE_TASKS.failure:
      case EXERCISE_TASKS.over:
      case EXERCISE_TASKS.success:
        feedbackTimer.current.start()
    }

    clearTimeout(activityTimeout.current)
    activityTimeout.current = setTimeout(
      handleActivityTimeout,
      ACTIVITY_TIMEOUT_MS
    )

    setIsContinueConfirmationOpen(false)
  }, [currentTask])

  const handleContiuneAbort = useCallback(async function () {
    const updateSessionPayload = {
      id: task.sid,
      durationMs: sessionTimer.current.calculateDuration(),
      durationMsFb: feedbackTimer.current.calculateDuration(),
    }

    try {
      const updateResponse = await graphqlClient.request(
        UPDATE_INPUT_SESSION_BY_ID_MUTATION,
        { ...updateSessionPayload }
      )
      appendSession(updateResponse.updateInputSessionById.inputSession)
    } catch (error) {
      console.error("failed to update input session on abort: ", error)
    }

    if (isBrowser) {
      try {
        const exerciseAbortActivity = new ExerciseActivity(
          window.location.pathname,
          EXERCISE.EXERCISE_ABORTED,
          student.id,
          "exercise_abort",
          task.word,
          keyboardContext.keyboardState.input,
          task.sid
        )

        await exerciseAbortActivity.writeToDB()
      } catch (error) {
        console.error("failed to log exercise aborting: ", error)
      }
    }
    navigate("/student/profile", { replace: true })
  }, [])

  const handleClearWarning = () => {
    console.log("clear warning")
    setWarning(null)
  }

  const handleAbort = useCallback(async () => {
    let updateSessionPayload = { id: task.sid }

    if (sessionTimer.current.isRunning) {
      sessionTimer.current.end()
      updateSessionPayload = {
        ...updateSessionPayload,
        durationMs: sessionTimer.current.calculateDuration(),
      }
    } else if (feedbackTimer.current.isRunning) {
      feedbackTimer.current.end()
      updateSessionPayload = {
        ...updateSessionPayload,
        durationMs: sessionTimer.current.calculateDuration(),
        durationMsFb: feedbackTimer.current.calculateDuration(),
      }
    }

    try {
      const updateResponse = await graphqlClient.request(
        UPDATE_INPUT_SESSION_BY_ID_MUTATION,
        { ...updateSessionPayload }
      )
      console.log("update response on abort: ", updateResponse)
      appendSession(updateResponse.updateInputSessionById.inputSession)
    } catch (error) {
      console.error("failed to update input session on abort: ", error)
    }

    // sessionTimer.current.reset()
    // feedbackTimer.current.reset()
    navigate("/student/profile", { replace: true })
  }, [task])

  const handleRetry = useCallback(async () => {
    feedbackTimer.current.end()

    const { isSolved } = exerciseState
    const { word, sid } = task

    try {
      const updatetLastSession = await switchInputSession(
        {
          id: sid,
          attempt: exerciseState.currentAttempt - 1,
          correct: isSolved,
          input: keyboardContext.keyboardState.input,
          word,
          durationMs: sessionTimer.current.calculateDuration(),
          durationMsFb: feedbackTimer.current.calculateDuration(),
          // !!student.beginnerLevel
          hitsCount: /*?*/ exerciseState.results.graphemeHits.count,
          // : null,
          // startTime: sessionTimer.current.timeStart,
        },
        {
          attempt: 0,
          input: "",
          correct: false,
          word: exerciseState.results.next,
          student: student.id,
        }
      )

      appendSession(updatetLastSession)

      setCurrentTask(EXERCISE_TASKS.solving)
      // sessionTimer.current.start()
    } catch (error) {
      console.error("failed to append exercise:", error)
    }
    keyboardContext.clearInput()
  }, [exerciseState, keyboardContext.keyboardState.input, student, task])

  const handleSubmit = useCallback(async () => {
    if (!keyboardContext.keyboardState.input) {
      setWarning(`Du musst zuerst Buchstaben eingeben! Versuche es erneut!`)
    } else if (
      exerciseState.inputs.includes(keyboardContext.keyboardState.input)
    ) {
      setWarning(`So hattest du es schon! Versuche es anders!`)
      keyboardContext.clearInput()
    } else {
      setExerciseState((prevState) => ({
        ...prevState,
        inputs: [...prevState.inputs, keyboardContext.keyboardState.input],
        feedback: DEFAULT_MESSAGES.feedback,
        feedforward: DEFAULT_MESSAGES.feedforward,
      }))

      try {
        setCurrentTask(EXERCISE_TASKS.validating)
        console.log("session timer on validating: ", sessionTimer.current)
        sessionTimer.current.end()

        const serviceResponse = await exerciseService(
          {
            request: task.request,
            sid: inputSessionId,
            uid: student.id,
            wordBuffer,
            setWordBuffer,
            wordBufferIndex,
            getNextWordFromList,
            beginnerLevel: student.beginnerLevel,
            ...exerciseState,
          },
          {
            value: keyboardContext.keyboardState.input,
            word: task.word,
          }
        )

        console.log("service response:", serviceResponse)

        const newCorrect = serviceResponse.matched.map(
          (e, i) => e || exerciseState.correct[i]
        )

        console.log("set feedforward")

        setExerciseState((prevState) => ({
          ...prevState,
          isSolved: true,
          correct: newCorrect,
          feedforward: getFeedForwardFromList(
            exerciseState.currentAttempt,
            true,
            serviceResponse.graphemeHits.letterCount
          ),
          results: { ...prevState.results, ...serviceResponse },
        }))

        wordBufferIndex.current = 0

        setCurrentTask(EXERCISE_TASKS.success)
        feedbackTimer.current.start()
      } catch (error) {
        console.error("service error:", error)

        // if (
        //   !!!student.beginnerLevel &&
        //   (exerciseState.currentAttempt === 2 || exerciseState.currentAttempt === task.maxAttempts)
        // ) {
        //   try {
        //     let fdFwd = ""

        //     if (error.current_error instanceof Array) {
        //       fdFwd = (
        //         await Promise.all(
        //           error.current_error.map(async (error) => {
        //             const response = await graphqlClient.request(
        //               FEEDBACK_QUERY,
        //               {
        //                 regel: error,
        //               }
        //             )
        //             console.log("response multi:", response)
        //             return response.feedbackByRegel.feedForward
        //           })
        //         )
        //       ).join("\n")
        //     } else {
        //       const response = await graphqlClient.request(FEEDBACK_QUERY, {
        //         regel: error.current_error,
        //       })
        //       console.log("response single:", response)
        //       fdFwd = response.feedbackByRegel.feedForward
        //     }

        //     console.log("feedforward:", fdFwd)
        //     setExerciseState((prevState) => ({
        //       ...prevState,
        //       feedforward: fdFwd,
        //     }))
        //   } catch (error) {
        //     console.error("failed at processing mistake:", error)
        //   }
        // } else if (!!student.beginnerLevel) {
        console.log("set feedforward")

        setExerciseState((prevState) => ({
          ...prevState,
          feedforward: getFeedForwardFromList(
            exerciseState.currentAttempt,
            false,
            error.graphemeHits.letterCount
          ),
        }))
        // }

        const newCorrect = error.matched.map(
          (e, i) => e || exerciseState.correct[i]
        )

        setCurrentTask(
          exerciseState.currentAttempt === task.maxAttempts
            ? EXERCISE_TASKS.over
            : EXERCISE_TASKS.failure
        )
        feedbackTimer.current.start()

        setExerciseState((prevState) => ({
          ...prevState,
          currentAttempt:
            prevState.currentAttempt < 4
              ? prevState.currentAttempt + 1
              : prevState.currentAttempt,
          isSolved: false,
          correct: newCorrect,
          results: { ...prevState.results, ...error },
        }))
      }
    }
  }, [keyboardContext.keyboardState.input, task, exerciseState])

  const handleNext = useCallback(async () => {
    feedbackTimer.current.end()

    keyboardContext.clearInput()

    try {
      await appendExercise({
        request: task.request,
        input: keyboardContext.keyboardState.input,
        attempt: exerciseState.currentAttempt,
        isSolved: exerciseState.isSolved,
        word: task.word,
        uid: task.uid,
        sid: task.sid,
        correct: exerciseState.correct,
        hitsCount: exerciseState.results.graphemeHits.count,
        // startTime: sessionTimer.current.timeStart,
      })

      await updateStars(
        calculateStarsFromAttempt({
          attempt: exerciseState.currentAttempt,
          hitsCount: exerciseState.results.graphemeHits.count,
          beginnerLevel: student.beginnerLevel
        })
      )

      setWord(exerciseState.results.next)
      setCurrentTask(EXERCISE_TASKS.solving)
      setExerciseState((prevState) => ({
        ...prevState,
        inputs: [],
      }))
    } catch (error) {
      console.error("failed to append exercise:", error)
    }
  }, [exerciseState, task])

  const appendExercise = useCallback(
    async (exercise) => {
      const updatedLastSession = await switchInputSession(
        {
          id: exercise.sid,
          attempt: exercise.attempt,
          correct: exercise.isSolved,
          input: exercise.input,
          word: exercise.word,
          durationMs: sessionTimer.current.calculateDuration(),
          durationMsFb: feedbackTimer.current.calculateDuration(),
          // startTime: sessionTimer.current.timeStart,
          hitsCount: exercise.hitsCount,
        },
        {
          attempt: 0,
          input: "",
          correct: false,
          word: exerciseState.results.next,
          student: student.id,
        }
      )

      appendSession(updatedLastSession)
    },
    [exerciseState.results, student]
  )

  const switchInputSession = useCallback(
    async (updatePayload, createPayload) => {
      console.log("input session id:", inputSessionId)

      const updateResponse = await graphqlClient.request(
        UPDATE_INPUT_SESSION_BY_ID_MUTATION,
        { ...updatePayload }
      )

      console.log("input session updated:", updateResponse)

      if (exerciseState.results.continue) {
        const createResponse = await graphqlClient.request(
          CREATE_INPUT_SESSION_MUTATION,
          { ...createPayload }
        )
        console.log("input session created:", createResponse)

        setInputSessionId(createResponse.createInputSession.inputSession.id)
        console.log(
          "set new session id:",
          createResponse.createInputSession.inputSession.id
        )

        return updateResponse.updateInputSessionById.inputSession
      } else {
        console.log(
          "cannot load next exercise for some reason, return to profile"
        )
        navigate("/student/profile", { replace: true })
      }
    },
    [exerciseState.results]
  )

  useEffect(() => {
    activityTimeout.current = setTimeout(
      handleActivityTimeout,
      ACTIVITY_TIMEOUT_MS
    )

    return () => {
      clearTimeout(activityTimeout.current)
    }
  }, [])

  useEffect(() => {
    if (activityTimeout.current) {
      clearTimeout(activityTimeout.current)
      activityTimeout.current = setTimeout(
        handleActivityTimeout,
        ACTIVITY_TIMEOUT_MS
      )
    }
  }, [keyboardContext.keyboardState.input])

  useEffect(() => {
    setLoadingTask(true)
    setExerciseState((prevState) => ({
      ...prevState,
      results: {},
      currentAttempt: 1,
    }))
    setLoadingTask(false)
  }, [task.word])

  useEffect(() => {
    setIsLastAttempt(exerciseState.currentAttempt === task.maxAttempts)
  }, [exerciseState.currentAttempt, task.maxAttempts])

  useEffect(() => {
    setShowElaborateFeedback(
      !!student.beginnerLevel || exerciseState.currentAttempt === 3
    )
  }, [exerciseState.currentAttempt, isLastAttempt, student])

  useEffect(() => {
    if (isLastAttempt)
      keyboardContext.setKeyboardState((prev) => ({
        ...prev,
        currentMode: "assist",
      }))
    else
      keyboardContext.setKeyboardState((prev) => ({
        ...prev,
        currentMode: "default",
      }))
  }, [isLastAttempt, keyboardContext.setKeyboardState])

  useEffect(() => {
    graphqlClient.request(UPDATE_INPUT_SESSION_BY_ID_MUTATION, {
      id: inputSessionId,
      startTime: Date.now(),
      word: task.word,
    })
  }, [inputSessionId])

  useEffect(() => {
    if (currentTask === EXERCISE_TASKS.solving) {
      sessionTimer.current.start()
    }
  }, [currentTask])

  useEffect(() => {
    keyboardContext.setIsActive(
      !warning && currentTask === EXERCISE_TASKS.solving
    )
    setHasWarning(!!warning)
    setIsSolvingNoWarning(currentTask === EXERCISE_TASKS.solving && !!!warning)
    setIsValidating(currentTask === EXERCISE_TASKS.validating)
    setIsFailure(currentTask === EXERCISE_TASKS.failure)
    setIsOver(currentTask === EXERCISE_TASKS.over)
    setIsSuccess(currentTask === EXERCISE_TASKS.success)
  }, [currentTask, warning, keyboardContext.setIsActive])

  useEffect(() => {
    console.log("current input: ", keyboardContext.keyboardState.input)
  }, [keyboardContext.keyboardState.input])

  return (
    <>
      <ConfirmationAlert
        isOpen={isContinueConfirmationOpen}
        toggleOpen={() => setIsContinueConfirmationOpen((prev) => !prev)}
        headerText="Hi! Noch da??"
        bodyText="Willst du weitermachen?"
        handleConfirm={handleContiuneConfirm}
        handleAbort={handleContiuneAbort}
      />
      <TaskListingDisplay
        task={task}
        exerciseState={exerciseState}
        requestedSequence={requestedSequence}
        attempts={attempts}
        handleAbort={handleAbort}
        handleClearWarning={handleClearWarning}
        handleRetry={handleRetry}
        handleNext={handleNext}
        isLastAttempt={isLastAttempt}
        isSolvingNoWarning={isSolvingNoWarning}
        isValidating={isValidating}
        isOver={isOver}
        isFailure={isFailure}
        isSuccess={isSuccess}
        hasWarning={hasWarning}
        showElaborateFeedback={showElaborateFeedback}
        warning={warning}
        handleSubmit={handleSubmit}
        loadingTask={loadingTask}
      />
    </>
  )
}

TaskListing.propTypes = {
  task: PropTypes.shape({
    maxAttempts: PropTypes.number.isRequired,
    request: PropTypes.arrayOf(PropTypes.string).isRequired,
    word: PropTypes.string.isRequired,
    sentence: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    audioWord: PropTypes.string.isRequired,
    audioSentence: PropTypes.string.isRequired,
    speechRate: PropTypes.number,
    speechRateSentence: PropTypes.number,
    maxInputLength: PropTypes.number.isRequired,
    sid: PropTypes.string.isRequired,
  }).isRequired,
  setWord: PropTypes.func.isRequired,
  inputSessionId: PropTypes.string.isRequired,
  setInputSessionId: PropTypes.func.isRequired,
  wordBuffer: PropTypes.array,
  setWordBuffer: PropTypes.func,
  wordBufferIndex: PropTypes.shape({
    current: PropTypes.number.isRequired,
  }),
  getNextWordFromList: PropTypes.func,
  getFeedForwardFromList: PropTypes.func,
}

export default TaskListing
