import React from "react"
import PropTypes from "prop-types"

import { StyledStarsFeedback } from "./StarsFeedbackStyles"
import Star from "../../lib/icons/Star"

const StarsFeedbackDisplay = ({ starsGiven, attempt }) => {
  return (
    <StyledStarsFeedback>
      <span>{attempt}. Versuch:</span>
      <span className="stars">
        {new Array(starsGiven).fill(0).map((_, i) => (
          <Star key={i} size={26} />
        ))}
      </span>
    </StyledStarsFeedback>
  )
}

StarsFeedbackDisplay.propTypes = {
  attempt: PropTypes.number,
  starsGiven: PropTypes.number,
}

export default StarsFeedbackDisplay
