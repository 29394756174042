import React from "react"
import PropTypes from "prop-types"

import ImageQuestionDisplay from "./ImageQuestionDisplay"

const ImageQuestion = ({ task, autoplay = false, ...props }) => {
  return <ImageQuestionDisplay task={task} autoplay={autoplay} {...props} />
}

ImageQuestion.propTypes = {
  task: PropTypes.shape({
    maxAttempts: PropTypes.number,
    request: PropTypes.arrayOf(PropTypes.string),
    word: PropTypes.string.isRequired,
    sentence: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    audioWord: PropTypes.string.isRequired,
    audioSentence: PropTypes.string.isRequired,
    speechRate: PropTypes.number.isRequired,
    speechRateSentence: PropTypes.number.isRequired,
    maxInputLength: PropTypes.number,
    sid: PropTypes.string.isRequired,
  }).isRequired,
  autoplay: PropTypes.bool,
}

export default ImageQuestion
