import React from "react"
import PropTypes from "prop-types"
import useSound from "use-sound"

import SpeakButtonDisplay from "./SpeakButtonDisplay"

const SpeakButtonContainer = ({
  audio,
  rate = 1,
  children,
  disabled = false,
  autoPlay = false,
  className,
  trackActivity,
}) => {
  const [play] = useSound(audio, {
    interrupt: true,
    playbackRate: rate,
    autoplay: autoPlay,
  })

  const handleClick = (e) => {
    try {
      trackActivity()
    } catch (error) {
      console.error("failed to log audio activity: ", error)
    }
    play()
    e.currentTarget.blur()
  }

  return (
    <SpeakButtonDisplay
      disabled={disabled}
      onClick={handleClick}
      className={className}
      audio={audio}
    >
      {children}
    </SpeakButtonDisplay>
  )
}

SpeakButtonContainer.propTypes = {
  audio: PropTypes.string.isRequired,
  speed: PropTypes.number,
  disabled: PropTypes.bool,
  autoPlay: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  trackActivity: PropTypes.func,
}

export default SpeakButtonContainer
