import React from "react"
import IconBackspace from "../../lib/icons/Backspace"
import IconShift from "../../lib/icons/Shift"
import {
  ASSIST_RESIST,
  EXHAUSTED_ENABLED,
  ANLAUT_KEYS,
} from "../../const_values"

function createIcon(imgName) {
  return (
    <img
      className="key-img"
      src={`https://files.talidu.net/images_keyboard/${imgName}.png`}
      alt=""
    />
  )
}

const KEYS_DEFAULT = [
  { id: "KeyQ", value: "q", row: 0 },
  { id: "KeyW", value: "w", row: 0 },
  {
    id: "KeyE",
    value: "e",
    row: 0,
    className: "vowel",
  },
  { id: "KeyR", value: "r", row: 0 },
  { id: "KeyT", value: "t", row: 0 },
  { id: "KeyY", value: "z", row: 0 },
  {
    id: "KeyU",
    value: "u",
    row: 0,
    className: "vowel",
  },
  {
    id: "KeyI",
    value: "i",
    row: 0,
    className: "vowel",
  },
  {
    id: "KeyO",
    value: "o",
    row: 0,
    className: "vowel",
  },
  { id: "KeyP", value: "p", row: 0 },
  {
    id: "BracketLeft",
    value: "ü",
    row: 0,
    className: "vowel",
  },
  {
    id: "Backspace",
    icon: <IconBackspace />,
    row: 0,
    className: "key-backspace",
  },
  {
    id: "KeyA",
    value: "a",
    row: 1,
    className: "vowel",
  },
  { id: "KeyS", value: "s", row: 1 },
  { id: "KeyD", value: "d", row: 1 },
  { id: "KeyF", value: "f", row: 1 },
  { id: "KeyG", value: "g", row: 1 },
  { id: "KeyH", value: "h", row: 1 },
  { id: "KeyJ", value: "j", row: 1 },
  { id: "KeyK", value: "k", row: 1 },
  { id: "KeyL", value: "l", row: 1 },
  {
    id: "Semicolon",
    value: "ö",
    row: 1,
    className: "vowel",
  },
  {
    id: "Quote",
    value: "ä",
    row: 1,
    className: "vowel",
  },
  { id: "Minus", value: "ß", row: 1 },
  {
    id: "Shift",
    value: "ABC",
    icon: <IconShift />,
    className: "key-shift",
    row: 2,
  },
  {
    id: "KeyZ",
    value: "y",
    className: "vowel",
    row: 2,
  },
  { id: "KeyX", value: "x", row: 2 },
  { id: "KeyC", value: "c", row: 2 },
  { id: "KeyV", value: "v", row: 2 },
  { id: "KeyB", value: "b", row: 2 },
  { id: "KeyN", value: "n", row: 2 },
  { id: "KeyM", value: "m", row: 2 },
]

const KEYS_SHIFT = [
  { id: "KeyQ", value: "Q", row: 0 },
  { id: "KeyW", value: "W", row: 0 },
  {
    id: "KeyE",
    value: "E",
    row: 0,
    className: "vowel",
  },
  { id: "KeyR", value: "R", row: 0 },
  { id: "KeyT", value: "T", row: 0 },
  { id: "KeyY", value: "Z", row: 0 },
  {
    id: "KeyU",
    value: "U",
    row: 0,
    className: "vowel",
  },
  {
    id: "KeyI",
    value: "I",
    row: 0,
    className: "vowel",
  },
  {
    id: "KeyO",
    value: "O",
    row: 0,
    className: "vowel",
  },
  { id: "KeyP", value: "P", row: 0 },
  {
    id: "BracketLeft",
    value: "Ü",
    row: 0,
    className: "vowel",
  },
  {
    id: "Backspace",
    icon: <IconBackspace />,
    row: 0,
    className: "key-backspace",
  },

  {
    id: "KeyA",
    value: "A",
    row: 1,
    className: "vowel",
  },
  { id: "KeyS", value: "S", row: 1 },
  { id: "KeyD", value: "D", row: 1 },
  { id: "KeyF", value: "F", row: 1 },
  { id: "KeyG", value: "G", row: 1 },
  { id: "KeyH", value: "H", row: 1 },
  { id: "KeyJ", value: "J", row: 1 },
  { id: "KeyK", value: "K", row: 1 },
  { id: "KeyL", value: "L", row: 1 },
  {
    id: "Semicolon",
    value: "Ö",
    row: 1,
    className: "vowel",
  },
  {
    id: "Quote",
    value: "Ä",
    row: 1,
    className: "vowel",
  },
  { id: "Minus", value: "ß", row: 1 },

  {
    id: "Shift",
    value: "abc",
    icon: <IconShift />,
    className: "key-shift key-shift--uppercase",
    row: 2,
  },
  {
    id: "KeyZ",
    value: "Y",
    className: "vowel",
    row: 2,
  },
  { id: "KeyX", value: "X", row: 2 },
  { id: "KeyC", value: "C", row: 2 },
  { id: "KeyV", value: "V", row: 2 },
  { id: "KeyB", value: "B", row: 2 },
  { id: "KeyN", value: "N", row: 2 },
  { id: "KeyM", value: "M", row: 2 },
]

const KEYS_SPECIAL = [
  /****************** row 1 ***********************/
  {
    id: "Backspace",
    icon: <IconBackspace />,
    area: 10,
    className: "key-backspace",
  },

  /****************** row 2 ***********************/
  {
    id: "KeyE",
    value: "e",
    area: 0,
    className: "vowel",
    icon: createIcon("E_Ente_Esel"),
    audioName: "E_Ente_Esel",
  },
  {
    id: "KeyA",
    value: "a",
    area: 0,
    className: "vowel",
    icon: createIcon("A_Ampel_Ameise"),
    audioName: "A_Ampel_Ameise",
  },
  {
    id: "KeyO",
    value: "o",
    area: 0,
    className: "vowel",
    icon: createIcon("O_Ordner_Oma"),
    audioName: "O_Ordner_Oma_Opa",
  },
  {
    id: "KeyU",
    value: "u",
    area: 0,
    className: "vowel",
    icon: createIcon("U_Unfall_Ufo"),
    audioName: "U_Unfall_Ufo",
  },
  {
    id: "KeyI",
    value: "i",
    area: 0,
    className: "vowel",
    icon: createIcon("I_Insel_Igel"),
    audioName: "I_Insel",
  },
  {
    id: "KeyIE",
    value: "ie",
    area: 0,
    className: "vowel",
    icon: createIcon("ie_Biene"),
    audioName: "ie_Biene",
  },
  {
    id: "KeyH",
    value: "h",
    area: 5,
    icon: createIcon("H_Hose"),
    audioName: "H_Hose",
  },
  {
    id: "KeyJ",
    value: "j",
    area: 5,
    icon: createIcon("J_Jacke"),
    audioName: "J_Jacke",
  },
  {
    id: "Keyß",
    value: "ß",
    area: 7,
    icon: createIcon("sz_Floesse"),
    audioName: "sz_Floeße",
  },

  /****************** row 3 ***********************/
  {
    id: "KeyMutA",
    value: "ä",
    area: 1,
    className: "vowel",
    icon: createIcon("Ae_Aepfel_Aehre"),
    audioName: "Ä_Äpfel_Ähre",
  },
  {
    id: "KeyMutO",
    value: "ö",
    area: 1,
    className: "vowel",
    icon: createIcon("Oe_Oeffner_Oel"),
    audioName: "Ö_Öffner_Öl",
  },
  {
    id: "KeyMutU",
    value: "ü",
    area: 1,
    className: "vowel",
    icon: createIcon("Ue_Ueberholverbot_Nuesse_2"),
    audioName: "Ü_Überholverbot_Nüsse",
  },
  {
    id: "KeyT",
    value: "t",
    area: 4,
    icon: createIcon("T_Tor"),
    audioName: "t_Tor",
  },
  {
    id: "KeyD",
    value: "d",
    area: 4,
    icon: createIcon("D_Dinosaurier"),
    audioName: "D_Dino",
  },
  {
    id: "KeyM",
    value: "m",
    area: 5,
    icon: createIcon("M_Maus"),
    audioName: "M_Maus",
  },
  {
    id: "KeyN",
    value: "n",
    area: 5,
    icon: createIcon("N_Nase"),
    audioName: "N_Nase",
  },
  {
    id: "KeyS",
    value: "s",
    area: 6,
    icon: createIcon("S_Sonne_Bus"),
    audioName: "S_Sonne",
  },
  {
    id: "KeyZ",
    value: "z",
    area: 6,
    icon: createIcon("Z_Zebra"),
    audioName: "Z_Zebra",
  },

  /****************** row 4 ***********************/
  {
    id: "KeyEI",
    value: "ei",
    area: 2,
    className: "vowel",
    icon: createIcon("Ei_Eis"),
    audioName: "Ei_Eis",
  },
  {
    id: "KeyAU",
    value: "au",
    area: 2,
    className: "vowel",
    icon: createIcon("Au_Auto"),
  },
  {
    id: "KeyC",
    value: "c",
    area: 3,
    icon: createIcon("C_Computer_Cent"),
    audioName: "C_Computer_Cent",
  },
  {
    id: "KeyV",
    value: "v",
    area: 3,
    icon: createIcon("V_Vogel_Vase"),
    audioName: "V_Vogel_Vase",
  },
  {
    id: "KeyP",
    value: "p",
    area: 4,
    icon: createIcon("P_Pilz"),
    audioName: "P_Pilz",
  },
  {
    id: "KeyB",
    value: "b",
    area: 4,
    icon: createIcon("B_Baum"),
    audioName: "B_Baum",
  },
  {
    id: "KeyF",
    value: "f",
    area: 5,
    icon: createIcon("F_Fisch"),
    audioName: "F_Fisch",
  },
  {
    id: "KeyW",
    value: "w",
    area: 5,
    icon: createIcon("W_Wolke"),
    audioName: "W_Wolke",
  },
  {
    id: "KeySCH",
    value: "sch",
    area: 6,
    icon: createIcon("Sch_Schere"),
    audioName: "Sch_Schere",
  },
  {
    id: "KeyCH",
    value: "ch",
    area: 6,
    icon: createIcon("Chklein_Buch_Milch"),
    audioName: "Ch_Chameleon_Milch",
  },

  /****************** row 5 ***********************/
  {
    id: "KeyEU",
    value: "eu",
    area: 2,
    className: "vowel",
    icon: createIcon("Eu_Euro"),
    audioName: "Eu_Euro",
  },
  {
    id: "KeyMutAU",
    value: "äu",
    area: 2,
    className: "vowel",
    icon: createIcon("Aeu_Maeuse"),
    audioName: "Äu_Mäuse",
  },
  {
    id: "KeyX",
    value: "x",
    area: 3,
    icon: createIcon("X_Xylophon"),
    audioName: "X_Xylophon",
  },
  {
    id: "KeyY",
    value: "y",
    area: 3,
    icon: createIcon("Yklein_Baby_Pyramide"),
    audioName: "y_Baby_Pyramide",
  },
  {
    id: "KeyK",
    value: "k",
    area: 4,
    icon: createIcon("K_Koffer"),
    audioName: "K_Koffer",
  },
  {
    id: "KeyG",
    value: "g",
    area: 4,
    icon: createIcon("G_Gabel"),
    audioName: "G_Gabel",
  },
  {
    id: "KeyL",
    value: "l",
    area: 5,
    icon: createIcon("L_Lampe"),
    audioName: "L_Lampe",
  },
  {
    id: "KeyR",
    value: "r",
    area: 5,
    icon: createIcon("R_Roller"),
    audioName: "R_Roller",
  },
  {
    id: "KeySP",
    value: "sp",
    area: 6,
    icon: createIcon("Sp_Spinne"),
    audioName: "Sp_Spinne",
  },
  {
    id: "KeyST",
    value: "st",
    area: 6,
    icon: createIcon("St_Stern"),
    audioName: "St_Stern",
  },

  /****************** row 6 ***********************/
  {
    id: "Shift",
    value: "ABC",
    icon: <IconShift />,
    className: "key-shift",
    area: 11,
  },
  {
    id: "KeyCK",
    value: "ck",
    area: 8,
    icon: createIcon("ck_Schnecke"),
    audioName: "ck_Schnecke",
  },
  {
    id: "KeyTZ",
    value: "tz",
    area: 8,
    icon: createIcon("tz_Katze"),
    audioName: "tz_Katze",
  },
  {
    id: "KeyCHS",
    value: "chs",
    area: 8,
    icon: createIcon("chs_Fuchs"),
    audioName: "chs_Fuchs",
  },
  {
    id: "KeyNG",
    value: "ng",
    area: 9,
    icon: createIcon("ng_Ring"),
    audioName: "ng_Ring",
  },
  {
    id: "KeyNK",
    value: "nk",
    area: 9,
    icon: createIcon("nk_Bank"),
    audioName: "nk_Bank",
  },
  {
    id: "KeyQU",
    value: "qu",
    area: 9,
    icon: createIcon("Qu_Quelle"),
    audioName: "Qu_Qualle",
  },
  {
    id: "KeyPF",
    value: "pf",
    area: 9,
    icon: createIcon("Pf_Pferd"),
    audioName: "Pf_Pferd",
  },
]

const KEYS_SPECIAL_SHIFT = [
  /****************** row 1 ***********************/
  {
    id: "Backspace",
    icon: <IconBackspace />,
    area: 10,
    className: "key-backspace",
  },

  /****************** row 2 ***********************/
  {
    id: "KeyE",
    value: "E",
    area: 0,
    className: "vowel",
    icon: createIcon("E_Ente_Esel"),
    audioName: "E_Ente_Esel",
  },
  {
    id: "KeyA",
    value: "A",
    area: 0,
    className: "vowel",
    icon: createIcon("A_Ampel_Ameise"),
    audioName: "A_Ampel_Ameise",
  },
  {
    id: "KeyO",
    value: "O",
    area: 0,
    className: "vowel",
    icon: createIcon("O_Ordner_Oma"),
    audioName: "O_Ordner_Oma_Opa",
  },
  {
    id: "KeyU",
    value: "U",
    area: 0,
    className: "vowel",
    icon: createIcon("U_Unfall_Ufo"),
    audioName: "U_Unfall_Ufo",
  },
  {
    id: "KeyI",
    value: "I",
    area: 0,
    className: "vowel",
    icon: createIcon("I_Insel_Igel"),
    audioName: "I_Insel",
  },
  {
    id: "KeyIE",
    value: "ie",
    area: 0,
    className: "vowel",
    icon: createIcon("ie_Biene"),
    audioName: "ie_Biene",
    disabled: true,
  },
  {
    id: "KeyH",
    value: "H",
    area: 5,
    icon: createIcon("H_Hose"),
    audioName: "H_Hose",
  },
  {
    id: "KeyJ",
    value: "J",
    area: 5,
    icon: createIcon("J_Jacke"),
    audioName: "J_Jacke",
  },
  {
    id: "Keyß",
    value: "ß",
    area: 7,
    icon: createIcon("sz_Floesse"),
    audioName: "sz_Floeße",
    disabled: true,
  },

  /****************** row 3 ***********************/
  {
    id: "KeyMutA",
    value: "Ä",
    area: 1,
    className: "vowel",
    icon: createIcon("Ae_Aepfel_Aehre"),
    audioName: "Ä_Äpfel_Ähre",
  },
  {
    id: "KeyMutO",
    value: "Ö",
    area: 1,
    className: "vowel",
    icon: createIcon("Oe_Oeffner_Oel"),
    audioName: "Ö_Öffner_Öl",
  },
  {
    id: "KeyMutU",
    value: "Ü",
    area: 1,
    className: "vowel",
    icon: createIcon("Ue_Ueberholverbot_Nuesse_2"),
    audioName: "Ü_Überholverbot_Nüsse",
  },
  {
    id: "KeyT",
    value: "T",
    area: 4,
    icon: createIcon("T_Tor"),
    audioName: "t_Tor",
  },
  {
    id: "KeyD",
    value: "D",
    area: 4,
    icon: createIcon("D_Dinosaurier"),
    audioName: "D_Dino",
  },
  {
    id: "KeyM",
    value: "M",
    area: 5,
    icon: createIcon("M_Maus"),
    audioName: "M_Maus",
  },
  {
    id: "KeyN",
    value: "N",
    area: 5,
    icon: createIcon("N_Nase"),
    audioName: "N_Nase",
  },
  {
    id: "KeyS",
    value: "S",
    area: 6,
    icon: createIcon("S_Sonne_Bus"),
    audioName: "S_Sonne",
  },
  {
    id: "KeyZ",
    value: "Z",
    area: 6,
    icon: createIcon("Z_Zebra"),
    audioName: "Z_Zebra",
  },

  /****************** row 4 ***********************/
  {
    id: "KeyEI",
    value: "Ei",
    area: 2,
    className: "vowel",
    icon: createIcon("Ei_Eis"),
    audioName: "Ei_Eis",
  },
  {
    id: "KeyAU",
    value: "Au",
    area: 2,
    className: "vowel",
    icon: createIcon("Au_Auto"),
  },
  {
    id: "KeyC",
    value: "C",
    area: 3,
    icon: createIcon("C_Computer_Cent"),
    audioName: "C_Computer_Cent",
  },
  {
    id: "KeyV",
    value: "V",
    area: 3,
    icon: createIcon("V_Vogel_Vase"),
    audioName: "V_Vogel_Vase",
  },
  {
    id: "KeyP",
    value: "P",
    area: 4,
    icon: createIcon("P_Pilz"),
    audioName: "P_Pilz",
  },
  {
    id: "KeyB",
    value: "B",
    area: 4,
    icon: createIcon("B_Baum"),
    audioName: "B_Baum",
  },
  {
    id: "KeyF",
    value: "F",
    area: 5,
    icon: createIcon("F_Fisch"),
    audioName: "F_Fisch",
  },
  {
    id: "KeyW",
    value: "W",
    area: 5,
    icon: createIcon("W_Wolke"),
    audioName: "W_Wolke",
  },
  {
    id: "KeySCH",
    value: "Sch",
    area: 6,
    icon: createIcon("Sch_Schere"),
    audioName: "Sch_Schere",
  },
  {
    id: "KeyCH",
    value: "Ch",
    area: 6,
    icon: createIcon("Chgross_Chameleon_Milch"),
    audioName: "Ch_Chameleon_Milch",
  },

  /****************** row 5 ***********************/
  {
    id: "KeyEU",
    value: "Eu",
    area: 2,
    className: "vowel",
    icon: createIcon("Eu_Euro"),
    audioName: "Eu_Euro",
  },
  {
    id: "KeyMutAU",
    value: "Äu",
    area: 2,
    className: "vowel",
    icon: createIcon("Aeu_Maeuse"),
    audioName: "Äu_Mäuse",
  },
  {
    id: "KeyX",
    value: "X",
    area: 3,
    icon: createIcon("X_Xylophon"),
    audioName: "X_Xylophon",
  },
  {
    id: "KeyY",
    value: "Y",
    area: 3,
    icon: createIcon("Ygross_Yak_Pyramide_2"),
    audioName: "Y_Yak_Pyramide",
  },
  {
    id: "KeyK",
    value: "K",
    area: 4,
    icon: createIcon("K_Koffer"),
    audioName: "K_Koffer",
  },
  {
    id: "KeyG",
    value: "G",
    area: 4,
    icon: createIcon("G_Gabel"),
    audioName: "G_Gabel",
  },
  {
    id: "KeyL",
    value: "L",
    area: 5,
    icon: createIcon("L_Lampe"),
    audioName: "L_Lampe",
  },
  {
    id: "KeyR",
    value: "R",
    area: 5,
    icon: createIcon("R_Roller"),
    audioName: "R_Roller",
  },
  {
    id: "KeySP",
    value: "Sp",
    area: 6,
    icon: createIcon("Sp_Spinne"),
    audioName: "Sp_Spinne",
  },
  {
    id: "KeyST",
    value: "St",
    area: 6,
    icon: createIcon("St_Stern"),
    audioName: "St_Stern",
  },

  /****************** row 6 ***********************/
  {
    id: "Shift",
    icon: <IconShift />,
    value: "abc",
    className: "key-shift",
    area: 11,
  },
  {
    id: "KeyCK",
    value: "ck",
    area: 8,
    icon: createIcon("ck_Schnecke"),
    audioName: "ck_Schnecke",
    disabled: true,
  },
  {
    id: "KeyTZ",
    value: "tz",
    area: 8,
    icon: createIcon("tz_Katze"),
    audioName: "tz_Katze",
    disabled: true,
  },
  {
    id: "KeyCHS",
    value: "chs",
    area: 8,
    icon: createIcon("chs_Fuchs"),
    audioName: "chs_Fuchs",
    disabled: true,
  },
  {
    id: "KeyNG",
    value: "ng",
    area: 9,
    icon: createIcon("ng_Ring"),
    audioName: "ng_Ring",
    disabled: true,
  },
  {
    id: "KeyNK",
    value: "nk",
    area: 9,
    icon: createIcon("nk_Bank"),
    audioName: "nk_Bank",
    disabled: true,
  },
  {
    id: "KeyQU",
    value: "Qu",
    area: 9,
    icon: createIcon("Qu_Quelle"),
    audioName: "Qu_Qualle",
  },
  {
    id: "KeyPF",
    value: "Pf",
    area: 9,
    icon: createIcon("Pf_Pferd"),
    audioName: "Pf_Pferd",
  },
]

const getAssistMode = (modes, characters = []) => {
  const baseMode = modes.default
  const activated = new Map(characters.map((k) => [k, false]))

  const keys = baseMode
    .map(
      ({ id, value, disabled = false, className, ...rest }) => {
        let assistMatch

        if (!ASSIST_RESIST.includes(id) && !id.includes("Shift")) {
          assistMatch = characters.find((char) =>
            ANLAUT_KEYS.includes(char) ? char === value : char.includes(value)
          )
        }

        if (assistMatch) {
          disabled = false
          className = `${className} key-assist`
          activated.set(value, true)
        } else if (!ASSIST_RESIST.includes(id)) {
          disabled = true
        }
        return { id, value, disabled, className, ...rest }
      }
      // Hide shift button in assist mode
    )
    .filter(({ value }) => !value?.includes("Shift"))

  Array.from(activated.entries())
    .filter(([_, v]) => !v)
    .forEach(function ([k]) {
      const key = modes.shift.find(({ value }) => value === k)
      if (!key) return

      activated.set(k, true)
      const index = keys.findIndex(({ id }) => id === key.id)
      keys.splice(index, 0, {
        ...key,
        originalId: key.id,
        id: `${key.id}_shift`,
        className: `${key.className} key-assist`,
        disabled: false,
        area: 12,
      })
    })

  return keys
}

export const getKeyboardModes = (beginnerLevel, characters) => {
  const baseModes = {
    default: !!beginnerLevel ? KEYS_SPECIAL : KEYS_DEFAULT,
    exhausted: (!!beginnerLevel ? KEYS_SPECIAL : KEYS_DEFAULT).map(
      ({ value, disabled, ...key }) => ({
        disabled: !EXHAUSTED_ENABLED.includes(value),
        value,
        ...key,
      })
    ),
    shift: !!beginnerLevel ? KEYS_SPECIAL_SHIFT : KEYS_SHIFT,
  }

  const assistMode = getAssistMode(baseModes, characters)
  const isAbsoluteBeginner = beginnerLevel === 1

  return {
    default: isAbsoluteBeginner ? assistMode : baseModes.default,
    exhausted: isAbsoluteBeginner
      ? assistMode.map(({ id, value, disabled, ...key }) => ({
          disabled: !EXHAUSTED_ENABLED.includes(id),
          value,
          id,
          ...key,
        }))
      : baseModes.exhausted,
    shift: isAbsoluteBeginner ? assistMode : baseModes.shift,
    assist: assistMode,
  }
}

const DEFAULT_MODES = {
  default: KEYS_DEFAULT,
  exhausted: KEYS_DEFAULT.map(({ id, disabled, ...key }) => ({
    disabled: !EXHAUSTED_ENABLED.includes(id),
    id,
    ...key,
  })),
  shift: KEYS_SHIFT,
}

export default DEFAULT_MODES
