import React, { useContext, useCallback } from "react"

import { KeyboardContext } from "../Keyboard/KeyboardProvider"

import AnswerInputDisplay from "./AnswerInputDisplay"

const AnswerInput = ({ handleSubmit, currentAttempt, ...props }) => {
  const { keyboardState, setKeyboardState } = useContext(KeyboardContext)
  const { input } = keyboardState

  const setInput = useCallback(
    (e) => {
      e.preventDefault()

      console.log("target value:", e.target.value)
      console.log("target input:", input)

      setKeyboardState((prev) => ({
        ...prev,
        input:
          e.target.value.length > prev.displayInput.length
            ? e.target.value.replace(/\s/g, "")
            : e.target.value.slice(0, -1).replace(/\s/g, ""),
        displayInput:
          e.target.value.length > prev.displayInput.length
            ? e.target.value + " "
            : prev.displayInput.slice(0, e.target.value.lastIndexOf(" ") + 1),
      }))
    },
    [input]
  )

  return (
    <AnswerInputDisplay
      handleSubmit={handleSubmit}
      setInput={setInput}
      currentAttempt={currentAttempt}
    />
  )
}

export default AnswerInput
