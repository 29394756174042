import React from "react"
import PropTypes from "prop-types"

import AttemptsListDisplay from "./AttemptsListDisplay"

const AttemptsList = ({
  current,
  isSolved = false,
  inputs,
  hideMissing = true,
  unleashTheCarnifex = false,
}) => {
  return (
    <AttemptsListDisplay
      current={current}
      isSolved={isSolved}
      inputs={inputs}
      hideMissing={hideMissing}
      unleashTheCarnifex={unleashTheCarnifex}
    />
  )
}

AttemptsList.propTypes = {
  current: PropTypes.number.isRequired,
  isSolved: PropTypes.bool,
  inputs: PropTypes.array,
  hideMissing: PropTypes.bool,
  unleashTheCarnifex: PropTypes.bool,
}

export default AttemptsList
