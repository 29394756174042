import { useState, useEffect } from "react"

const useKeyboardConfig = (modes, currentMode) => {
  const [keys, setKeys] = useState()
  const [keysDisabled, setKeysDisabled] = useState([])

  const mode = modes.hasOwnProperty(currentMode) ? currentMode : "default"

  useEffect(() => {
    const currentMode = modes[mode]
    setKeys(currentMode)
    setKeysDisabled(
      currentMode
        .filter(({ disabled = false }) => !!disabled)
        .map(({ id, value }) => ({ id, value }))
    )
  }, [mode, modes])
  return [keys, keysDisabled, mode]
}

export default useKeyboardConfig
