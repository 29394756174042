import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 103 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48.324 17.072l-15.715-2.283L25.584.549a1.113 1.113 0 00-1.92 0l-7.027 14.236-15.72 2.287a1.07 1.07 0 00-.595 1.825l11.374 11.085-2.684 15.652a1.07 1.07 0 001.552 1.128l14.057-7.39 14.056 7.387a1.071 1.071 0 001.552-1.129l-2.684-15.648 11.374-11.085a1.07 1.07 0 00-.594-1.825h-.001z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M101.665 17.072L85.95 14.789 78.925.549a1.113 1.113 0 00-1.919 0l-7.028 14.236-15.72 2.287a1.071 1.071 0 00-.594 1.825l11.374 11.085-2.685 15.652a1.07 1.07 0 001.552 1.128l14.057-7.39 14.057 7.387a1.07 1.07 0 001.552-1.129l-2.685-15.648 11.374-11.085a1.072 1.072 0 00-.058-1.584 1.07 1.07 0 00-.536-.241h-.001z"
        fill="url(#prefix__paint1_linear)"
      />
      <g filter="url(#prefix__filter0_d)">
        <path
          d="M79.626 24.734L61.294 22.07 53.099 5.46a1.298 1.298 0 00-2.239 0l-8.199 16.607-18.336 2.667a1.248 1.248 0 00-.694 2.129l13.268 12.93-3.132 18.259a1.248 1.248 0 001.81 1.316l16.398-8.62 16.398 8.616a1.248 1.248 0 001.81-1.316l-3.132-18.254L80.32 26.863a1.248 1.248 0 00-.693-2.13h-.001z"
          fill="url(#prefix__paint2_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={19.29}
          y1={18.5}
          x2={34.79}
          y2={39.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F49A25" />
          <stop offset={1} stopColor="#E25B15" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={71.79}
          y1={18}
          x2={86.79}
          y2={40.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F49A25" />
          <stop offset={1} stopColor="#E25B15" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={43.79}
          y1={26}
          x2={56.79}
          y2={42}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59F26" />
          <stop offset={1} stopColor="#E25B15" />
        </linearGradient>
        <filter
          id="prefix__filter0_d"
          x={13.255}
          y={4.819}
          width={77.441}
          height={74.691}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={10} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent
