import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import StarsFeedbackDisplay from "./StarsFeedbackDisplay"
import { calculateStarsFromAttempt } from "../../services/helpers"
import { useStudentStore } from "../../../store"

const StarsFeedback = ({ attempt, hitsCount }) => {
  const [starsGiven, setStarsGiven] = useState(0)
  const student = useStudentStore((store) => store.student)

  useEffect(() => {
    if (student.id) {
      setStarsGiven(
        calculateStarsFromAttempt({
          attempt: attempt,
          hitsCount: hitsCount,
          beginnerLevel: student.beginnerLevel,
        })
      )
      console.log("hits count:", hitsCount)
    }
  }, [attempt, hitsCount])

  return <StarsFeedbackDisplay starsGiven={starsGiven} attempt={attempt} />
}

StarsFeedback.propTypes = {
  attempt: PropTypes.number,
  hitsCount: PropTypes.number,
}

export default StarsFeedback
