import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
      <title>{"sound"}</title>
      <path d="M20.454 2.109a1 1 0 00-1.042.082L8.675 10H1a1 1 0 00-1 1v10a1 1 0 001 1h7.675l10.737 7.809a.997.997 0 001.042.082A1 1 0 0021 29V3a1 1 0 00-.546-.891zM25.071 11l-.707-.707-1.414 1.414.707.707a5.076 5.076 0 010 7.172l-.707.707 1.414 1.414.707-.707a7.08 7.08 0 000-10z" />
      <path d="M27.799 6.858l-1.414 1.414.707.707C28.968 10.854 30 13.348 30 16s-1.032 5.146-2.908 7.021l-.707.707 1.414 1.414.707-.707C30.759 22.182 32 19.187 32 16s-1.241-6.182-3.494-8.435l-.707-.707z" />
    </svg>
  )
}

export default SvgComponent
