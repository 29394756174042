import React, { useRef, useContext } from "react"

import Key from "../Key"
import { StyledKeyboard, StyledKeyboardSpecial } from "./KeyboardStyles"
import { KeyboardContext } from "./KeyboardProvider"

import { UI_EVENT_NAMES } from "../../const_values"
import { useStudentStore } from "../../../store"
import { ExerciseActivity } from "../../services/helpers"

const { EXERCISE } = UI_EVENT_NAMES
const isBrowser = typeof window !== "undefined"

const keysToExclude = ["ArrowLeft, ArrowRight, Slash"]

const KeyboardDisplay = ({ task, ...props }) => {
  const { student } = useStudentStore((store) => store)
  const {
    keyboardState,
    maxLength,
    setKeyboardState,
    isKeyDisabled,
    checkIsKeyActive,
    activateKey,
    deactivateKey,
  } = useContext(KeyboardContext)
  const { input, displayInput, currentMode, rows, areas, keysHaveRows } =
    keyboardState
  const Keyboard = keysHaveRows ? StyledKeyboard : StyledKeyboardSpecial
  const inputCharacters = useRef([])

  return (
    <Keyboard {...props}>
      {(keysHaveRows ? rows : areas).map((zone, i) => (
        <div
          className={
            keysHaveRows ? `row${i === 3 ? " space" : ""}` : `area${i}`
          }
          key={keysHaveRows ? `row-${i}` : `area-${i}`}
        >
          {zone.map(({ id, value, ...config }) => (
            <Key
              key={id}
              active={checkIsKeyActive(id)}
              value={value}
              colspan={id === "Shift" ? 2 : 1}
              handleActivate={() => {
                console.log(`key ${id} pressed`)
                activateKey(id)
              }}
              handleDeactivate={() => {
                console.log(`key ${id} released`)
                deactivateKey(id)
              }}
              handleKeyEvent={async () => {
                console.log(`key ${id} has been clicked.`)
                if (!keysToExclude.includes(id)) {
                  if (id === "Backspace") {
                    if (input) {
                      if (isBrowser) {
                        try {
                          const deleteActivity = new ExerciseActivity(
                            window.location.pathname,
                            EXERCISE.INPUT_DELETED,
                            student.id,
                            "keyboard_backspace",
                            task.word,
                            input,
                            task.sid
                          )
                          await deleteActivity.writeToDB()
                        } catch (error) {
                          console.error("failed to log deleting: ", error)
                        }
                      }

                      setKeyboardState((prev) => ({
                        ...prev,
                        input: input.slice(
                          0,
                          -1 * inputCharacters.current.pop().length
                        ),
                        displayInput: displayInput
                          .trimEnd()
                          .slice(
                            0,
                            displayInput.trimEnd().lastIndexOf(" ") + 1
                          ),
                        currentMode:
                          input.length - 1 < maxLength
                            ? props.currentAttempt === 4
                              ? "assist"
                              : "default"
                            : "exhausted",
                        cursorPosition:
                          prev.cursorPosition !== 0
                            ? prev.cursorPosition - 1
                            : prev.cursorPosition,
                      }))
                    }
                  } else if (id === "ShiftLeft" || id === "Shift") {
                    setKeyboardState((prev) => ({
                      ...prev,
                      currentMode:
                        currentMode === "default"
                          ? "shift"
                          : currentMode === "shift"
                          ? "default"
                          : currentMode,
                    }))
                  } else {
                    if (isBrowser) {
                      try {
                        const enterActivity = new ExerciseActivity(
                          window.location.pathname,
                          EXERCISE.INPUT_ENTERED,
                          student.id,
                          `keyboard_${value}`,
                          task.word,
                          input,
                          task.sid
                        )
                        enterActivity.writeToDB()
                      } catch (error) {
                        console.error("failed to log entering input: ", error)
                      }
                    }
                    inputCharacters.current.push(value)
                    setKeyboardState((prev) => ({
                      ...prev,
                      currentMode:
                        input.length + value.length >= maxLength
                          ? "exhausted"
                          : input.length === 0 && currentMode === "shift"
                          ? "default"
                          : currentMode,
                      lastMode:
                        input.length + value.length >= maxLength
                          ? prev.currentMode === "exhausted"
                            ? prev.lastMode
                            : prev.currentMode
                          : prev.lastMode,
                      input:
                        input.length !== maxLength && !isKeyDisabled(value)
                          ? input.concat(value)
                          : input,
                      displayInput:
                        input.length !== maxLength && !isKeyDisabled(value)
                          ? displayInput.concat(`${value} `)
                          : displayInput,
                      cursorPosition: prev.cursorPosition + value.length,
                    }))
                  }
                }
              }}
              {...config}
            />
          ))}
          {keysHaveRows &&
            i === 2 &&
            Array.from({ length: 3 }).map((_) => <div />)}
        </div>
      ))}
    </Keyboard>
  )
}

export default KeyboardDisplay
