import React, { useContext } from "react"

import { StyledImageQuestion } from "./ImageQuestionStyles"
import SpeakerIcon from "../../lib/icons/Speaker"
import MessageIcon from "../../lib/icons/Message"
import SpeakButton from "../Speech/"

import { KeyboardContext } from "../Keyboard/KeyboardProvider"
import { UI_EVENT_NAMES } from "../../const_values"
import { ExerciseActivity } from "../../services/helpers"
import { useStudentStore } from "../../../store"

const { EXERCISE } = UI_EVENT_NAMES

const isBrowser = typeof window !== "undefined"

const audioEvents = {
  sentence: EXERCISE.SENTENCE_LISTENED,
  word: EXERCISE.WORD_LISTENED,
}

const ImageQuestionDisplay = ({ task, autoPlay, ...props }) => {
  const { student } = useStudentStore((store) => store)
  const { keyboardState } = useContext(KeyboardContext)

  const trackAudio = async (audioType) => {
    if (audioType !== "sentence" && audioType !== "word") {
      throw new Error(
        'parameter "audioType must be either "sentence" ore "word"'
      )
    } else if (isBrowser) {
      const audioActivity = new ExerciseActivity(
        window.location.pathname,
        audioEvents[audioType],
        student.id,
        `speak_button_${audioType}`,
        task.word,
        keyboardState.input,
        task.sid
      )

      audioActivity.writeToDB()
    }
  }

  return (
    <StyledImageQuestion imageSrc={task.img} {...props}>
      <div className="image-container">
        <img src={task.img} className="image" />
      </div>
      <div className="speak-buttons">
        <div className="speak-button speak-sentence-button">
          <SpeakButton
            audio={task.audioSentence}
            rate={task.speechRateSentence}
            disabled={!task.sentence}
            trackActivity={async () => await trackAudio("sentence")}
          >
            <MessageIcon />
          </SpeakButton>
        </div>

        <div className="speak-button speak-word-button">
          <SpeakButton
            audio={task.audioWord}
            rate={task.speechRate}
            disabled={!task.word}
            autoPlay={autoPlay}
            trackActivity={async () => await trackAudio("word")}
          >
            <SpeakerIcon />
          </SpeakButton>
        </div>
      </div>
    </StyledImageQuestion>
  )
}

export default ImageQuestionDisplay
