import React from "react"

function IconShift(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M6.4 19.537V14H1L12 3l11 11h-5.443v5.537z"
        stroke="black"
        strokeWidth="2"
        fill="none"
      />
    </svg>
  )
}

export default IconShift
