import styled from "styled-components"

const Letter = styled.span`
  display: inline-block;
  border-radius: 0.25em;
  margin: ${({ theme: { spacings } }) => spacings.fluid.tiny / 4}vmin;
  padding: ${({ theme: { spacings } }) => spacings.fluid.tiny / 8}vmin
    ${({ theme: { spacings } }) => spacings.fluid.tiny / 6}vmin;
  user-select: none;
`

export default Letter
