import React from "react"

import { StyledAttemptsList } from "./AttemptsListStyles"
import Word from "../Word"

const AttemptsListDisplay = ({
  current,
  isSolved,
  inputs,
  hideMissing,
  unleashTheCarnifex,
}) => (
  <StyledAttemptsList className="attempts">
    {inputs.map((input, i) => (
      <li
        className="attempt"
        key={`attempt-${i}`}
        data-variant={
          i + 1 < current
            ? "failed"
            : i + 1 === current && isSolved
            ? "success"
            : i + 1 === current
            ? "current"
            : null
        }
      >
        <span className="attempt-count">{i + 1}</span>
        <Word
          unleashTheCarnifex={unleashTheCarnifex}
          hideMissing={hideMissing}
          segments={input}
        />
      </li>
    ))}
  </StyledAttemptsList>
)

export default AttemptsListDisplay
